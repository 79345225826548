.form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.save-button {
    background-color: #6200EE15 !important;
    color: #6200EE !important;
    width: 9rem;
    font-weight: bold !important;
    border-radius: 0.5rem;
}

.save-button:hover {
    background-color: #2A75EA;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.label {
    color: "#636161";
    font-weight: bold !important;
}

.error {
    font-size: 12px;
    color: red;
}


/* toast */
.confirmation-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white !important;
}

.confirmation-box-label {
    color: #636161 !important;
    padding-bottom: 20px !important;
    font-size: 14px !important;
}

.icon {
    font-size: 2rem;
    color: #374F80;
}

.p-toast-icon-close {
    display: none;
}


@media (max-width: 375px) {
    .form {
        gap: 20px !important;
    }
}
