.top-default-section {
    padding-bottom: 65px;
}

.topRight {
    padding-top: 100px;
}

.default-section {
    background-color: white;
    max-width: 100%;
    padding: 30px;
    box-shadow: 1px 2px 5px #cccccc;
    border-radius: 0.3rem;
    margin-bottom: 20px;
}

.topRight-default-section {
    background-color: rgba(98, 0, 238, 0.05);
    max-width: 100%;
    padding: 30px;
    box-shadow: 1px 2px 5px #cccccc;
    border-radius: 0.3rem;
    margin-bottom: 20px;
}

.divider {
    height: 27px !important;
}

.top-divider {
    height: 27px;
}

.inner-padding {
    padding-left: 20px;
    padding-right: 30px;
    padding-bottom: 24px;
}

.top-inner-padding {
    padding-left: 20px;
    padding-right: 40px;
}

.border-bottom {
    border-bottom: 1px solid lightgrey;
    padding-top: 7px;
}

.top-border-bottom {
    border-bottom: 1px solid lightgrey;
    padding-top: 7px;
    padding-bottom: 7px;
}

.icon-inside {
    text-align: center;
    height: 30px;
    width: 30px;
}

.small-title {
    font-size: 14px !important;
    color: #747474;
    width: 56%;
    padding: 5px 0px 0px 20px;
}

.top-small-title {
    font-size: 13px !important;
    color: #747474;
    width: 70%;
    padding: 5px 0px 10px 20px;
}

.top10-small-title {
    font-size: 14px !important;
    color: #747474;
    width: 35%;
    padding: 5px 0px 0px 20px;
}

.small-count {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #747474;
    width: 50%;
    padding: 5px 0px 0px 30px;
    border-left: 1px soild lightgrey;
}

.top-small-count {
    font-size: 15px !important;
    font-weight: 600 !important;
    color: #747474;
    width: 50%;
    padding: 5px 0px 0px 25px;
    border-left: 1px soild lightgrey;
}

.section-title {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #545454;
    padding-left: 15px;
    padding-bottom: 20px;
}

.top-section-title {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #545454;
    padding-left: 15px;
    padding-bottom: 20px;
}

.section-container {
    display: flex;
    justify-content: center;
}

.button-container {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(7, 1fr);
}

.filter-button {
    border: 1px solid #6200EE !important;
    font-size: 12px !important;
}

.gridFilter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 5px !important;
    align-items: center;
    padding: 20px 0 18px 0 !important;
    justify-content: center;
}

.calendar-label {
    font-size: 13px !important;
    color: #545454;
    font-weight: 600;
}

.mainDataSection{
    display: flex;
    padding: 10px 45px; 
    transition: all 0.2s ease-in-out; 
}

.mainTextSection{
    padding: 5px;
}

.main-small-title{
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #747474;
}

.main-small-count {
    font-size: 13px !important;
    color: #747474;
}

@media (max-width: 393px) {
    .inner-padding {
        padding-left: 20px;
        padding-right: 30px !important;
        padding-bottom: 24px;
    }

    .top-divider {
        height: 42px !important;
    }

    .top-small-title {
        font-size: 13px !important;
        width: 90%;
        padding: 5px 0px 10px 15px;
    }

    .top-small-count {
        padding: 5px 0px 0px 20px;
    }
}


@media (max-width: 600px) {
    .inner-padding {
        padding-left: 20px;
        padding-right: 30px;
        padding-bottom: 24px;
    }

    .top-divider {
        height: 35px !important;
    }

    .top-small-title {
        width: 60%;
        padding: 5px 0px 10px 10px !important;
    }

    .button-container {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-gap: 15px !important;
        padding-bottom: 22px !important;
        padding-top: 15px !important;
    }

    .filter-button {
        font-size: 12px !important;
    }

    .top10-small-title {
        width: 56%;
    }

    .mainDataSection{
        padding: 10px 30px; 
    }
}

@media (max-width: 900px) {
    .top-default-section {
        padding-bottom: 0px !important;
    }
    
    .topRight {
        padding-top: 60px !important;
    }
}

@media (max-width: 1469px) {
    .button-container {
        grid-gap: 15px;
        grid-template-columns: repeat(4, 1fr);
        padding-bottom: 22px;
        padding-top: 15px;
    }
    
    .top-default-section {
        padding-bottom: 60px;
    }
    
    .topRight {
        padding-top: 100px;
    }

    .top-section-title {
        padding-left: 10px;
    }

    .top-inner-padding {
        padding-left: 10px;
        padding-right: 10px;
    }

    .top-small-title {
        font-size: 13px !important;
        width: 90%;
        padding: 5px 0px 10px 15px;
    }

    .top10-small-title {
        width: 56%;
    }

    .small-count {
        /* width: 50%; */
        padding: 5px 0px 0px 15px;
    }

}

@media (max-width: 1024px) {

}