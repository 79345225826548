.search-details {
    padding: 20px 40px 0 40px;
    font-size: 13px !important;
}

.no-search-details {
    padding: 20px 40px 0 40px;
    /* font-size: 13px !important; */
}

.search-dialog-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    border-radius: 0.5rem;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
}

.ok-box {
    padding: 20px 50px 20px 20px;
    text-align: end;
}

.no-ok-box {
    padding: 20px 60px 20px 20px;
    text-align: end;
}

.ok-button {
    background-color: #2A75EA !important;
}

.searchTitle {
    font-size: 13px !important;
    font-weight: 600px !important;
    text-align: center;
}

.searchValue {
    font-size: 13px !important;
    text-align: center;
}

.searchBox-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(164, 169, 252, 0.3);
    border-radius: 0.5rem;
}

.header-text {
    color: #6200EE;
    /* font-size: 13px !important; */
}

.searchValueContainer {
    height: 170px;
    overflow-y: auto;
}

.borderedRow {
    border: 1px solid rgb(164, 169, 252);
    border-radius: 0.5rem;
}

.tableCellWithBorder {
    border-right: 1px solid rgb(164, 169, 252);
}