.login-form-component {
    /* padding-top: 2rem !important; */
    margin: 2rem auto !important;
    border-radius: 1.5rem !important;
}

.login-field-component {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.login-field-button {
    background-color: #b795e8 !important;
    color: black !important;
}



@media (max-width: 576px) {

    /* big container */
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 70vh;
    }

    /* lucky serial text */
    .title-component {
        width: 100%;
        text-align: center;
    }

    /* lucky serial text */
    .title-text {
        font-weight: bold !important;
        font-size: 26px !important;
        color: #141414;
        /* padding-bottom: 10px; */
    }


    /* form component */
    .login-form-component {
        width: 70%;
    }


    /* login field */
    .login-field-component {
        grid-column: auto !important;
        row-gap: 25px;
        /* padding-bottom: 10px; */
    }


    /* login button */
    .login-button-component {
        margin-top: 30px !important;
    }

    /* login button */
    .login-field-button {
        width: 100%;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        font-size: 14px !important;
    }

}



@media(min-width: 600px) {

    /* big container */
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 70vh;
    }

    /* lucky serial text */
    .title-component {
        width: 100%;
        /* padding: 1rem 6%; */
        text-align: center;
    }

    /* lucky serial text */
    .title-text {
        font-weight: bold !important;
        font-size: 36px !important;
        color: #141414;
        padding-bottom: 10px;
    }


    /* form component */
    .login-form-component {
        width: 50%;
    }

    /* login field */
    .login-field-component {
        grid-gap: 30px;
    }


    /* login button */
    .login-button-component {
        margin-top: 30px !important;
    }

    /* login button */
    .login-field-button {
        width: 100%;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        font-size: 18px !important;
    }
}