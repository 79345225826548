@media (max-width: 576px) {
    .css-204u17-MuiDataGrid-main {
        font-size: 12px;
    }

    .css-128fb87-MuiDataGrid-toolbarContainer {
        grid-template-columns: repeat(2, minmax(2, 1fr));
    }

    .css-ixi0wp .MuiDataGrid-toolbarContainer .MuiButton-text {
        font-size: 12px;
    }
}